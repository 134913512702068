import React from "react"
import LayoutDay from "../../../components/Chronology/LayoutDay"
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft"
import ContentRight from "../../../components/Chronology/Modules/ContentRight"
import InlineLink from "../../../components/Chronology/Modules/InlineLink"
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable"
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu"
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine"
import ModImage from "../../../components/Chronology/Modules/ModImage"
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu"
import ModText from "../../../components/Chronology/Modules/ModText"

export const frontmatter = {
  title: "Día 102",
  week: "Semana 15",
  day: "23",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-23",
  path: "/cronologia/semana-15#dia-23-jun/",
}

const Day102 = props => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En los últimos siete días se han diagnosticado en <strong>España</strong> 1.787 casos
          mediante pruebas PCR. En ese mismo periodo, 151 personas han requerido hospitalización
          (8,4% de los diagnósticos), de los que 13 han sido ingresos en la UCI, y se han producido
          14 fallecimientos.
        </ModText>
        <ModText>
          Los Ministerios de Sanidad, Educación y Formación Profesional han ultimado la{" "}
          <InlineLink link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/educacion/Documents/2020/230620-Guia-educacion.pdf">
            guía de recomendaciones
          </InlineLink>{" "}
          con medidas de prevención, higiene y promoción de la salud para el curso 2020-21 con las
          aportaciones de las Consejerías de Educación.
        </ModText>
        <ModText>
          El documento ofrece un marco común que puede ser adaptado en cada comunidad autónoma e
          implementado según la realidad de cada centro educativo y de su contexto local.
        </ModText>
        <ModImage src="/images/svg/estudiantes-colegio.svg" alt="estudiantes en colegio" />
      </ContentRight>
    </LayoutDay>
  )
}
export default Day102
